/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    var $grid = null;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        common: {
            init: function () {
                $(document).foundation(); // Foundation JavaScript
                new WOW().init();

                Sage.common.slideshow();
                Sage.common.nav();
                Sage.common.collapsible();
                Sage.common.mobile_nav();
            },
            collapsible: function () {
                $(".collapsible__link").on("click", function () {
                    var el = $(this).parents(".collapsible");
                    if (el.hasClass("collapsible--collapsed")) {
                        el.removeClass("collapsible--collapsed");
                        $("span", this).text("Read Less");
                        $("i", this)
                            .removeClass("fa-chevron-down")
                            .addClass("fa-chevron-up");
                    } else {
                        el.addClass("collapsible--collapsed");
                        $("span", this).text("Read More");
                        $("i", this)
                            .removeClass("fa-chevron-up")
                            .addClass("fa-chevron-down");
                    }
                    return false;
                });
            },
            nav: function () {
                $("header.header .nav a").on("click", function () {
                    if ($(this).attr("href") === "#") {
                        var next = $(this)
                            .next()
                            .find("li")
                            .first()
                            .find("a")
                            .attr("href");
                        if (next.length > 0) {
                            window.location = next;
                        } else {
                            return false;
                        }
                    }
                });
            },
            slideshow: function () {
                $(".slideshow").each(function () {
                    var mySwiper = new Swiper($(".swiper-container", this), {
                        speed: 400,
                        spaceBetween: 100,
                        loop: true,
                        autoHeight: true,
                        pagination: {
                            type: "fraction",
                            el: $(".slideshow__pagination", this),
                            clickable: true,
                        },
                        navigation: {
                            nextEl: $(".slideshow__next", this),
                            prevEl: $(".slideshow__prev", this),
                        },
                    });
                });
            },
            mobileLinkList: function () {
                $(".mobile_link_list select").on("change", function () {
                    if ($(this).val()) {
                        window.location.href = $(this).val();
                    }
                });
            },
            getUrlParameter: function (name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
                var results = regex.exec(location.search);
                return results === null
                    ? ""
                    : decodeURIComponent(results[1].replace(/\+/g, " "));
            },
            searchNav: function () {
                $("a.search_toggle").click(function () {
                    var search_link = $(this).parents(".search_link");
                    if ($(search_link).hasClass("open")) {
                        $(".searchform").fadeOut("fast");
                        $("a.search_toggle i", search_link)
                            .removeClass("fa-close")
                            .addClass("fa-search");
                        $(search_link).removeClass("open");
                    } else {
                        $(".searchform").fadeIn("fast");
                        $("input[type=text]", search_link).select().focus();
                        $("a.search_toggle i", search_link)
                            .removeClass("fa-search")
                            .addClass("fa-close");
                        $(search_link).addClass("open");
                    }
                    return false;
                });
            },
            mobile_nav: function () {
                $("button.hamburger").click(function () {
                    $(this).toggleClass("active");
                    $("body").toggleClass("menu_open");
                    return false;
                });
                $(".mobile_nav .menu-item-has-children span").click(
                    function () {
                        $(this)
                            .parents(".menu-item-has-children")
                            .toggleClass("is-open");
                    }
                );
            },
        },
        home: {
            slider: null,
            updateSlider: function () {
                if (Sage.home.slider) {
                    var curr = Sage.home.slider.activeIndex;
                    var img = $("#home-slider .swiper-slide")
                        .eq(curr)
                        .data("image");

                    if ($(".slide_img")) {
                        $(".slide_img--wrap").html(
                            '<div class="slide_img wow doFadeInUp"></div>'
                        );
                        $(".slide_img").css(
                            "backgroundImage",
                            "url(" + img + ")"
                        );
                    } else {
                        $(".slide_img")
                            .removeClass("doFadeInUp")
                            .addClass("fadeOut");
                    }
                }
            },
            init: function () {
                Sage.home.slider = new Swiper(
                    "#home-slider .swiper-container",
                    {
                        speed: 400,
                        autoplay: {
                            delay: 5000,
                        },
                        autoHeight: true,
                        effect: "fade",
                        fadeEffect: {
                            crossFade: true,
                        },
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true,
                        },
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        on: {
                            init: Sage.home.updateSlider,
                        },
                    }
                );
                $(window).on("load", Sage.home.updateSlider);
                Sage.home.slider.on("slideChange", Sage.home.updateSlider);
            },
        },
        page_template_template_about: {
            timeline: null,
            offset: 0,
            updateScale: function () {
                $(".scale__year")
                    .eq(Sage.page_template_template_about.timeline.activeIndex)
                    .addClass("scale__year--active")
                    .siblings()
                    .removeClass("scale__year--active");
            },
            updateOffset: function () {
                if ($(".timeline .swiper-slide").length > 0) {
                    if ($(window).width() < 767) {
                        Sage.page_template_template_about.offset = 0;
                        return;
                    }
                    if ($(window).width() < 1200) {
                        // 90 per silde @ 2 slides per view
                        Sage.page_template_template_about.offset =
                            $(".timeline .swiper-slide").length * 60;
                        return;
                    }
                    Sage.page_template_template_about.offset =
                        $(".timeline .swiper-slide").length * 60;
                    return;
                }
            },
            init: function () {
                Sage.page_template_template_about.updateOffset();
                Sage.page_template_template_about.timeline = new Swiper(
                    $(".timeline .swiper-container"),
                    {
                        speed: 400,
                        freeMode: true,
                        mousewheel: true,
                        slidesPerView: 3,
                        spaceBetween: 60,
                        slidesOffsetAfter:
                            Sage.page_template_template_about.offset,
                        breakpoints: {
                            766: {
                                spaceBetween: 30,
                                slidesPerView: 1,
                            },
                            1200: {
                                spaceBetween: 30,
                                slidesPerView: 2,
                            },
                        },
                    }
                );
                Sage.page_template_template_about.updateScale();
                Sage.page_template_template_about.timeline.on(
                    "slideChange",
                    Sage.page_template_template_about.updateScale
                );

                // on click go to the nearest point
                $(".scale__year").on("click", function () {
                    Sage.page_template_template_about.timeline.slideTo(
                        $(this).index()
                    );
                    return false;
                });

                $(window).on("resize", function () {
                    Sage.page_template_template_about.updateOffset();
                    Sage.page_template_template_about.timeline.params.slidesOffsetAfter =
                        Sage.page_template_template_about.offset;
                    Sage.page_template_template_about.timeline.update();
                });
            },
        },
        calendar: {
            resizeReady: false,
            init: function () {
                $("#calendar").fullCalendar({
                    columnFormat: "ddd",
                    timeFormat: "h(:mm)t",
                    defaultView: "month",
                    events: function (start, end, timezone, callback) {
                        $("#calendar .hasEvents").removeClass("hasEvents");
                        var data = {
                            action: "search_events",
                            start: start._d,
                            end: end._d,
                        };
                        $.ajax({
                            type: "POST",
                            url:
                                $("a.logo").attr("href") +
                                "/wp-admin/admin-ajax.php",
                            data: data,
                            success: function (data) {
                                // fix ampersand
                                data = data.replace(/&amp;/g, "&");
                                callback(JSON.parse(data));
                                Sage.calendar.resizeReady = true;
                            },
                        });
                    },
                    eventRender: function (event, element, view) {
                        var dateString = moment(event.start).format(
                            "YYYY-MM-DD"
                        );
                        $("#calendar")
                            .find('.fc-day[data-date="' + dateString + '"]')
                            .addClass("hasEvents");
                        $("#calendar")
                            .find('.fc-day-top[data-date="' + dateString + '"]')
                            .addClass("hasEvents");
                    },
                });

                $(".event_filters").on("submit", function (e) {
                    e.preventDefault();
                    $("#calendar").fullCalendar("refetchEvents");
                });

                $(".filter input").on("change", function (e) {
                    e.preventDefault();
                    $("#calendar").fullCalendar("refetchEvents");
                });
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
